import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, navigate } from "gatsby"
import BlogCard from "./blog-card"

const BlogWrapper = ({ className = "", pageContext }) => {
  const blogQueryData = useStaticQuery(BLOG_QUERY)
  const blogs = blogQueryData?.allSeaBlog?.nodes
  const categories = blogQueryData?.allSeaBlogCategory?.nodes

  const [windowWidth, setWindowWidth] = useState(1920)

  const [sortType, setSortType] = useState("newest-first")
  const [selectedCategory, setSelectedCategory] = useState(
    (typeof window != "undefined" &&
      (window?.history?.state?.category || "all")) ||
    "all"
  )

  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  const handleCategorySelect = (cat) => {
    if (cat && cat === "all") {
      navigate(`/blog/`, {
        state: { category: cat },
      })
    }
    if (cat && cat !== "all") {
      navigate(`/blog/${cat}/`, {
        state: { category: cat },
      })
    }
  }

  return (
    <BlogStyling className={className}>
      <h1>All articles</h1>
      <SortContainer>
        <StyledSelect
          onChange={(event) => handleCategorySelect(event.target.value)}
          defaultValue={selectedCategory}
        >
          <option value="default" disabled>
            Category
          </option>
          <option value="all">All</option>
          {categories.map((category, index) => (
            <option value={category.slug} key={index}>
              {category.title}
            </option>
          ))}
        </StyledSelect>
        <StyledSelect
          onChange={(event) => setSortType(event.target.value)}
          defaultValue="newest-first"
        >
          <option value="default" disabled>
            Sort by
          </option>
          <option value="oldest-first">Oldest first</option>
          <option value="newest-first">Newest first</option>
        </StyledSelect>
      </SortContainer>
      {blogs
        .sort((a, b) => {
          if (sortType === "newest-first") {
            return a.epoch > b.epoch ? -1 : 1
          } else {
            return b.epoch > a.epoch ? -1 : 1
          }
        })
        .map((blog, index) =>
          selectedCategory === "all" ||
            blog.categories
              .map((cat) => cat.slug === selectedCategory)
              .reduce((a, b) => a || b) ? (
            windowWidth > 480 ? (
              <BlogCard blog={blog} key={index}></BlogCard>
            ) : (
              <Link to={`/blog/${blog.slug}`} key={index}>
                <BlogCard blog={blog}></BlogCard>
              </Link>
            )
          ) : null
        )}
    </BlogStyling>
  )
}

export default BlogWrapper

const BlogStyling = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const SortContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
  }
`

const StyledSelect = styled.select`
  font: normal normal normal 16px/30px Poppins;
  width: 100%;
  padding: 1rem 20px;
  option {
    padding: 1rem 0;
  }
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 2px;
  outline: none;
  background-color: ${({ theme }) => theme.colors.lightgrey2};
  @media (min-width: ${({ theme }) => theme.breakMedium}) {
    border-right-width: 20px;
    width: 300px;
  }
  margin: 10px;
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 300px;
    border-right-width: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin: 10px 0 10px 0;
    max-width: 100%;
    width: 100%;
  }
`

// ===============
//      QUERY
// ===============
const BLOG_QUERY = graphql`
  {
    allSeaBlog {
      nodes {
        author
        slug
        title
        excerpt
        date
        epoch
        content
        read_time
        categories {
          slug
          name
        }
        thumbnail {
          source_url
        }
      }
    }
    allSeaBlogCategory {
      nodes {
        slug
        title
      }
    }
  }
`
