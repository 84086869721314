import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import BlogWrapper from "../components/blog/blog-wrapper"

const BlogPage = ({ pageContext, location }) => {
  const blogQueryData = useStaticQuery(BLOG_QUERY)

  return (
    <Layout
      pathContext={pageContext}
      location={location}
      bannerDetails={{
        title: blogQueryData.seaPage.title,
        description: blogQueryData.seaPage.description,
        image:
          blogQueryData.seaPage.featured_image.source_url,
      }}
    >
      <SEO title={"Blogs"} location={location} yoast={blogQueryData.seaPage.seo} />
      <BlogWrapper></BlogWrapper>
    </Layout>
  )
}

export default BlogPage

// ===============
//      QUERY
// ===============

const BLOG_QUERY = graphql`
  {
    seaPage(slug: { eq: "blog" }) {
      title
      description
      slug
      featured_image {
        source_url
      }
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
