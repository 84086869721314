import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable/cloudflare-image"

const BlogCard = ({ blog }) => {
  return (
    <Container>
      <ImageSection>
        <CloudflareImage
          src={blog.thumbnail.source_url}
          alt={blog.author}
          objectFit="cover"
        />
      </ImageSection>
      <InfoSection>
        <TitleContainer>
          <TitleStyled>{blog.title}</TitleStyled>
          <ReadTime>{blog.read_time}</ReadTime>
        </TitleContainer>
        <Description>{blog.excerpt}</Description>
        <Author className="light no-transform">
          by {blog.author} &middot; {blog.date}
        </Author>
        <LinkStyled to={`/blog/${blog.slug}`}>
          <button>Read article</button>
        </LinkStyled>
      </InfoSection>
    </Container>
  )
}

export default BlogCard

const Container = styled.div`
  width: 100%;
  height: 400px;
  box-shadow: 0px 3px 20px #0000001a;
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
    height: fit-content;
  }
`

const ImageSection = styled.div`
  width: 50%;
  height: 100%;
  background-color: grey;

  & > div {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
  }
`

const InfoSection = styled.div`
  width: 50%;
  height: 100%;
  padding: 30px 85px 30px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    justify-content: space-around;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 20px 40px 20px 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: 100%;
    height: fit-content;
    justify-content: space-between;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleStyled = styled.h4`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    text-overflow: unset;
    overflow: unset;
    display: unset;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-bottom: 5px;
  }
`
const ReadTime = styled.p`
  color: ${({ theme }) => theme.colors.brandHover};

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-top: auto;
  }
`

const Description = styled.p`
  margin-top: 0 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    display: none;
  }
`
const Author = styled.h5`
  font-style: italic;
  margin-top: auto;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.lightgrey5};

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-top: 16px;
    margin-bottom: 0;
  }
`
const LinkStyled = styled(Link)`
  width: 300px;
  height: 50px;

  & > button {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    display: none;
  }
`
